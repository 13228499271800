import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class ServiceArea {
  createdate: string;
  updatedate: string;
  createdById: string;
  lastModifiedById: string;
  id: string;
  name: string;
  status: string;
  isDeleted: string;
}

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ServiceAreaServices {
  constructor(private http: HttpClient) { }

  getServiceAreaList(): Observable<any> {
    const get_url = baseApiUrl + 'serviceArea/byStatus/active';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as ServiceArea[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPlanServiceArea(partnerId): Observable<any> {
    const get_url = baseApiUrl + 'getServiceAreaByPartner/' + partnerId ;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getonuList(){
    const get_url = baseApiUrl + 'commonList/generic/onuType';
    return this.http.get<any>(get_url);                                        
  }

  getLeadSource(){
    const get_url = baseApiUrl + 'commonList/generic/leadSource';
    return this.http.get<any>(get_url);                                       
  }

  getDetails(id){
    const get_url = baseApiUrl + 'lead/customer/getBasicCustomerDetails/' + id;
    return this.http.get<any>(get_url);                                       
  }
  
}
